import { ref } from "vue";
import { useRequest } from "xz-use";
import { message } from "ant-design-vue";
import { LoginParams, sendSms } from "@/services/user";

export const useForm = () => {
  const form = ref<LoginParams>({
    loginType: 1,
    password: "",
    username: "",
  });
  const formRef = ref<any>(null);
  return { form, formRef };
};

// sendSms
// 发送验证码
export const useSendSms = () => {
  return useRequest((params) => sendSms(params), {
    manual: true,
    onSuccess: () => {
      message.success("验证码发送成功");
    },
  });
};
