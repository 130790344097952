
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import setting from "@/config/setting";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "@/store/index";
import { useForm, useSendSms } from "./use";
import { goingLogin } from "@/config/axios-config";
import { useCountDown } from "xz-use";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    // onMounted(() => {
    //   if (setting.takeToken()) {
    //     goHome();
    //   }
    // });
    const autoLogin = ref<any>(false);
    let loading = ref<any>(false);
    const timing = ref<boolean>(false);
    const { countdown, setTargetDate } = useCountDown({
      onEnd: () => {
        timing.value = false;
      },
    });
    const checkImageId = ref<string>(""); // img验证码id
    const checkImageUrl = ref<string>(""); // img验证码url
    const imgCodeIsTrue = ref<boolean>(false);
    const { form, formRef } = useForm();
    const { run: sendSmsRun, loading: sendSmsLoading } = useSendSms();
    function trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }

    const redirect = computed(() => {
      return route.query?.redirect || "/system/selectSystem";
    });

    const otherQuery = computed(() => {
      return Object.keys(route.query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = route.query[cur];
        }
        return acc;
      }, {});
    });

    onMounted(() => {
      goingLogin.value = false;
    });

    // 登录
    const doSubmit = async () => {
      loading.value = true;
      try {
        await formRef.value?.validateFields();
        if ((form.value as any)?.username) {
          (form.value as any).username = trim((form.value as any).username);
        }
        if ((form.value as any)?.mobileNo) {
          (form.value as any).mobileNo = trim((form.value as any).mobileNo);
        }
        await store.dispatch("user/Login", {
          ...form.value,
        });
        router.replace({
          path: redirect.value as any,
          query: otherQuery.value,
        });
      } catch (e) {
        console.error(e);
      }
      loading.value = false;
    };

    const goHome = () => {
      // const query = route.query;
      // const path: string | null = query && query.from ? query.from : "/";
      // router.push(path).catch(() => {});
    };

    // 登录方式切换
    const changeLogintype = (loginType) => {
      form.value.loginType = loginType;

      if (loginType === 1) {
        form.value = {
          loginType: 1,
          password: "",
          username: (form.value as any).mobileNo,
        };
      } else {
        form.value = {
          loginType: 2,
          mobileCode: "",
          mobileNo: (form.value as any).username,
          // imgCode: "",
        };
      }
    };

    //获取图片验证码
    const getCaptcha = () => {
      console.log("获取图片验证码");
      // const {
      //   data: { data },
      // } = await get(`/sms/getCaptcha?${getUniqueId()}`);
      // setCheckImageId(data.id);
      // const url = getUrl(`/captcha/image/${data.id}?reload=true`);
      // setCheckImageUrl(url);
    };

    // 获取手机验证码
    const getMobileCode = async () => {
      // await formRef.value?.validateFields(["mobileNo", "imgCode"]);
      await formRef.value?.validateFields(["mobileNo"]);
      const mobileNo = (form.value as any).mobileNo;
      await sendSmsRun({ mobileNo, type: 1 });
      timing.value = true;
      setTargetDate(Date.now() + 60000);
    };

    return {
      form,
      formRef,
      loading,
      timing,
      // imgCodeIsTrue,
      sendSmsLoading,
      countdown,
      // autoLogin,
      doSubmit,
      changeLogintype,
      // getCaptcha,
      getMobileCode,
    };
  },
});
